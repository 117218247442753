export const environment = {
    API_UNA: "https://staging-api.provjereno.info/api/v1",
    API_PARENT_CATEGORY: "/categories/parent",
    API_TAG: "/articles/tag",
    API_POST: "/articles/slug",
    API_SEARCH: "/articles/search",
    API_AUTHOR: "/articles/author",
    API_SOURCE: "/articles/source",
    API_STATIC: "/pages/slug",
    API_NAV: "/menu",
    API_CATEGORY: "/articles/category",
    API_HOME: "/article-collections",
    API_LIVE_PREVIEW: "/articles/preview",
    API_HOME_LIVE_PREVIEW: "/article-collections/preview",
    API_PAGES: "/pages",
    API_NOTIFICATION: "/notifications/breaking-news",
    API_TV_PROGRAM: "/tv/program-schemas",
    API_POPULAR: "/articles/most-read",
    API_LATEST: "/articles/latest",
    API_IS_CHILD_CATEGORY: "/categories/is-child",
    API_GALLERY_BIH: "https://staging-api.provjereno.info/api/v1/galleries/show",
    API_POLL: "/polls",
    API_POLL_VOTE: "/option",
    API_ALL_NEWS: "/articles/listing",
    API_CREATED_BY: "/articles/created-by",
    API_COMENTS_LIST: "/comments",
    API_COMMENTS_LIKE: "/comments/like",
    API_CACHE_REFRESH: "/cache/get-version",

    API_WEATHER_FORECAST: "https://api.weatherapi.com/v1/current.json",

    APP_BLACK_HEADER: "0",

    APP_UNDER_CONSTRUCTION: "0",
    APP_PORT: 4000,

    APP_RECAPTCHA_PUBLIC_KEY: "6LdGXW8qAAAAAOatF-jiImfCWMQNDRi6uD5_U13u",
    APP_WEATHER_API_KEY: "8fabae65a708460a80b122820240811",

    APP_TITLE: "provjereno.info developmnent",
    APP_HOME_META_TITLE: "Najnovije Vesti, Događaji i Analize | provjereno.info",
    APP_HOME_META_DESCRIPTION:
        "Pratite najnovije vesti iz zemlje i sveta na provjereno.info. Donosimo vam aktuelne informacije, analize, sport, politiku, zabavu, tehnologiju i još mnogo toga. Budite u toku!",
    APP_HOME_META_KEYWORDS:
        "vesti, najnovije vesti, aktuelnosti, politika, sport, zabava, ekonomija, tehnologija, svet, lokalne vesti, provjereno.info",
};
